import Account from "app/Account/config";
import Agent from "app/Agent/config";
import Booking from "app/Booking/config";
import DN from "app/DN/config";
import Event from "app/Event/config";
import Inventory from "app/Inventory/config";
import ManageTables from "app/ManageTables/config";
import OnlineBooking from "app/OnlineBooking/config";
import Order from "app/Order/config";
import Portal from "app/Portal/config";
import PotentialClients from "app/PotentialClients/config";
// import Sellers from "app/Sellers/config";
import Translate from "app/Translate/config";

const serviceProviders = {
    Account,
    Agent,
    Booking,
    DN,
    Event,
    Inventory,
    ManageTables,
    OnlineBooking,
    Order,
    Portal,
    PotentialClients,
    // Sellers,
    Translate,
};

export default serviceProviders;
